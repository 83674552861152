<template>
  <div class="memberShip">
    <div class="shipContent content_area">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: this.$t("membership"),
    };
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.memberShip {
  height: 100%;
  background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/ship2.jpg") no-repeat;
  background-size: 100% 100%;
  padding: 24px 0 60px;
  .shipContent {
    background: #ffffff;
    padding: 47px 0 74px;
  }
}
/deep/ .editor_container  {
  padding-top: 10px;
  .edui-btn-toolbar {
    height: 0px;
  }
  .edui-toolbar  {
    display: none;
  }
   .edui-editor-body  {
    padding-top: 2px;
  }
   p  {
    line-height: 20px;
    margin: 0px;
    color: #666;
  }
  .edui-body-container  {
    width: 672px !important;
    height: 240px !important;
    overflow-x: hidden !important;
  }
  .edui-container  {
    box-shadow: none;
    border: 1px solid #dcdfe6;
  }
}
</style>
